import { useEffect } from 'react';

import Head from 'next/head';
import moment from 'moment';

import { withAuth } from '@/HOC';
import { getNewsList } from '@/apis/requests/news';
import { getMonthlyPrograms } from '@/apis/requests/monthlyprogram';
import { routingServerAndClientSide } from '@/utils';

import { DefaultLayout } from '@/layouts';
import { Main } from '@/components/main';
import { Popup } from '@/components/Popup';
import { fetchPopups } from '@/apis/requests/popup';

const parseProgramsForSwipeBoard = (programsInfo = []) => {
  return programsInfo?.map(({ program }) => ({
    key: `program-${program.boardId}`,
    boardId: program.boardId,
    href: `/monthlyprogram`,
    imageLocation: 'server',
    imageURL: program.imageURL,
    createdDate: program.createdDate,
    type: program.type,
    title: program.title,
  }));
};

const getGuidesForSwipeBoard = () => {
  return [
    {
      key: 'guide-1',
      boardId: 1,
      href: '/guide#guideHours',
      imageLocation: 'assets',
      imageURL: '../static/images/main/d-news/observation_time_color.svg',
      createdDate: null,
      type: 'information',
      title: '관람 안내_시간',
    },
    {
      key: 'guide-2',
      boardId: 2,
      href: '/guide#guideAdmission',
      imageLocation: 'assets',
      imageURL: '../static/images/main/d-news/observation_fare_color.svg',
      createdDate: null,
      type: 'information',
      title: '관람 안내_요금',
    },
    {
      key: 'guide-3',
      boardId: 3,
      href: '/guide#guideItems',
      imageLocation: 'assets',
      imageURL: '../static/images/main/d-news/gigaticket_composition_color.svg',
      createdDate: null,
      type: 'information',
      title: '관람 안내_기가티켓 구성',
    },
    {
      key: 'guide-4',
      boardId: 4,
      href: '/guide#guideDiscount',
      imageLocation: 'assets',
      imageURL: '../static/images/main/d-news/discount_color.svg',
      createdDate: null,
      type: 'information',
      title: '관람 안내_할인',
    },
    {
      key: 'guide-5',
      boardId: 5,
      href: '/guide#guideMap',
      imageLocation: 'assets',
      imageURL: '../static/images/main/d-news/way_to_come_color.svg',
      createdDate: null,
      type: 'information',
      title: '관람 안내_오시는 길',
    },
  ];
};

const parseNewsForSwipeBoard = (newsContents = []) => {
  return newsContents?.map((newsContent) => ({
    key: `program-${newsContent.boardId}`,
    boardId: newsContent.boardId,
    href: `/news/detail?id=${newsContent.boardId}`,
    imageLocation: 'server',
    imageURL: newsContent.imageURL,
    createdDate: newsContent.createdDate,
    type: newsContent.type,
    title: newsContent.title,
  }));
};

const Home = (props) => {
  let { newsList = {}, monthlyProgram = {} } = props;
  newsList = newsList ? newsList : {};
  const { content: newsContents = [] } = newsList;
  const programsContents = monthlyProgram?.programs ?? [];

  const news = parseNewsForSwipeBoard(newsContents);
  const guides = getGuidesForSwipeBoard();
  const programs = parseProgramsForSwipeBoard(programsContents);

  useEffect(() => {
    openPopup();
  }, []);

  const openPopup = async () => {
    const today = moment().format('YYYY-MM-DDTHH:mm:ss');
    const { data: popupsData } = await fetchPopups({ date: today });
    const popups = popupsData.data;

    if (popups.length === 0) return;

    const noPopupDate = localStorage.getItem('noPopupDate');
    const isNoPopupDate = moment(noPopupDate).isAfter(moment());
    if (isNoPopupDate) return;

    const { todayNotShow } = await Popup.open({
      props: {
        popups: popups.splice(0, 5),
      },
      uniqueID: 'main-popup',
    });
    if (!todayNotShow) return;
    const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
    localStorage.setItem('noPopupDate', tomorrow);
  };

  return (
    <>
      <Head>
        <title>Nexon Computer Museum - Main</title>
      </Head>
      <Main board={{ news, guides, programs }} />
    </>
  );
};

Home.getInitialProps = async (context) => {
  try {
    const { data } = await getNewsList({
      filter: {
        size: 5,
        direction: 'DESC',
      },
    });
    const responsePrograms = await getMonthlyPrograms({
      offset: 0,
      limit: 1,
    });
    const [monthlyProgram] = responsePrograms?.data?.data?.items ?? [];

    return { newsList: data?.data, monthlyProgram };
  } catch (error) {
    const { res } = context;
    return routingServerAndClientSide({ res, path: '/notfound' });
  }
};

Home.getLayout = function getLayout(page) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export default withAuth(Home);
