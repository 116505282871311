import { promiseModal } from '@/components/commons/System/System';
import { Carousel } from './Carousel';
import styles from './Popup.module.scss';
import { useEffect, useState } from 'react';
import { Checkbox } from '@/components';

const PopupModal = ({ popups, onClose, resolve, reject }) => {
  const [isChecked, setIsChecked] = useState(false);

  const checkNotShow = (event) => {
    const { checked } = event.target;
    setIsChecked(checked);
  };

  const closePopup = () => {
    onClose();
    return resolve({ confirm: true, todayNotShow: isChecked });
  };

  return (
    <div className={styles.dimmed}>
      <div className={styles.container}>
        <div className={styles.title}>Nexon Computer Museum</div>
        <div className={styles.carouselContainer}>
          <Carousel popups={popups} />
        </div>
        <div className={styles.footer}>
          <Checkbox className={styles.todayNotShow} darkMode={true} checked={isChecked} onChange={checkNotShow}>
            오늘 하루 그만보기
          </Checkbox>
          <button className={styles.closeButton} onClick={closePopup}>
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

const Popup = {
  open: ({ props, uniqueID }) => promiseModal(PopupModal, { props, uniqueID }),
};

export default Popup;
