import ServerAPI from '@/apis/server';
import MonthlyProgramBook from '@/apis/books/monthlyprogram.json';

const getMonthlyPrograms = (payload) => {
  return ServerAPI.get(MonthlyProgramBook.REST, {
    params: payload,
  });
};

export { getMonthlyPrograms };
