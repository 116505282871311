import ServerAPI from '@/apis/server';
import NewsBook from '@/apis/books/news.json';

const getNewsList = (payload) => {
  const { pageParam = 0, filter, ...otherPayload } = payload;
  return ServerAPI.get(NewsBook.REST, {
    params: { ...filter, page: pageParam, ...otherPayload },
  });
};

const getNews = (payload) => {
  const { id } = payload;

  return ServerAPI.get(`${NewsBook.REST}/${id}`);
};

export { getNewsList, getNews };
