import { useCallback, useEffect, useRef, useState } from 'react';
import { cx } from '@emotion/css';
import styles from './Carousel.module.scss';
import { FileImage } from '@/components';

const Carousel = ({ popups }) => {
  const timer = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalLength = popups.length;

  const intervalCallback = useCallback(() => {
    const nextIndex = (cur) => {
      if (cur >= totalLength - 1) return 0;
      else return cur + 1;
    };
    setCurrentIndex((prevState) => nextIndex(prevState));
  }, [totalLength]);

  useEffect(() => {
    timer.current = setInterval(intervalCallback, 3000);
    return () => clearInterval(timer.current);
  }, [intervalCallback, totalLength]);

  const moveCarousel = (index) => {
    clearInterval(timer.current);
    setCurrentIndex(index);
    timer.current = setInterval(intervalCallback, 3000);
  };

  const openLink = (link) => {
    if (!link) return;
    window.open(link, '_blank');
  };

  const isShowIndicator = () => {
    return popups.length > 1;
  };

  return (
    <div className={styles.container}>
      <ul className={styles.imageContainer}>
        {popups.map((popup, index) => (
          <li key={popup.popupID} className={cx(styles.image, currentIndex === index && styles.showImage)}>
            <FileImage src={popup.imageURL} alt={'popup-image'} onClick={() => openLink(popup.linkURL)} />
          </li>
        ))}
      </ul>
      {isShowIndicator() && (
        <ul className={styles.indicatorContainer}>
          {popups.map((popup, index) => (
            <li key={popup.popupID} className={cx(styles.indicator, currentIndex === index && styles.selectedIndicator)} onClick={() => moveCarousel(index)} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default Carousel;
